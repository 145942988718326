const translations = {
  en: {
    header: {
      add: "Add Passenger",
      edit: "Edit Passenger",
    },
    footer: {
      add: "Add Passenger",
      edit: "Save Passenger",
    },
    fields: {
      name: "Name",
      gender: "Select Gender",
      age: "Age",
      nationality: "Nationality",
      food: "Select Catering Service Option",
      passport: "Passport",
      dob: "Date of Birth",
    },
    placeholders: {
      name: "Enter Full Name",
      age: "Enter Age",
      passport: "Enter Passport Number",
      dob: "Enter Date of Birth",
    },
    options: {
      gender: {
        male: "Male",
        female: "Female",
        trans: "Transgender",
      },
      foods: {
        veg: "Vegetarian",
        jain: "Jain meal",
        vegd: "Veg (Diabetic)",
        nonvegd: "Non Veg (Diabetic)",
        tea: "Tea/Coffee",
        nonveg: "Non-Vegetarian",
        snacks: "Evening-Snacks",
        none: "No Food Choice",
      },
    },
    helpers: {
      name: "Name must be as per the valid Id proof",
    },
  },
  hi: {
    header: {
      add: "यात्री जोड़ें",
      edit: "यात्री एडिट करें",
    },
    footer: {
      add: "यात्री जोड़ें",
      edit: "यात्री सेव करें",
    },
    fields: {
      name: "नाम",
      gender: "जेंडर का चयन करें",
      age: "आयु",
      nationality: "राष्ट्रीयता",
      food: "भोजन की पसंद का चयन करें",
      passport: "पासपोर्ट",
      dob: "जन्म की तारीख",
    },
    placeholders: {
      name: "पूरा नाम दर्ज करें",
      age: "आयु दर्ज करें",
      passport: "पासपोर्ट नंबर दर्ज करें",
      dob: "जन्म् की तारीख़ दर्ज करे",
    },
    options: {
      gender: {
        male: "पुरुष",
        female: "महिला",
        trans: "ट्रांसजेंडर",
      },
      foods: {
        veg: "शाकाहारी",
        tea:"चाय/कॉफी",
        jain: "जैन भोजन", 
        vegd: "वेज (डायबिटिक)",
        nonvegd: "नॉन वेज (डायबिटिक)",
        nonveg: "मांसाहारी",
        snacks:"शाम का नाश्ता",
        none: "नहीं",
      },
    },
    helpers: {
      name: "नाम वैध आईडी प्रमाण के अनुसार होना चाहिए",
    },
  },
  gu: {
    header: {
      add: "પેસેન્જર ઉમેરો",
      edit: "પેસેન્જરને સંપાદિત કરો",
    },
    footer: {
      add: "પેસેન્જર ઉમેરો",
      edit: "પેસેન્જરને બચાવો",
    },
    fields: {
      name: "નામ",
      gender: "જાતિ પસંદ કરો",
      age: "ઉંમર",
      nationality: "રાષ્ટ્રીયતા",
      food: "ખોરાકની પસંદગી કરો",
      passport: "પાસપોર્ટ",
      dob: "જન્મ તારીખ",
    },
    placeholders: {
      name: "પૂરું નામ દાખલ કરો",
      age: "ઉંમર દાખલ કરો",
      passport: "તમારો પાસપોર્ટ નંબર દાખલ કરો.",
      dob: "જન્મ તારીખ દાખલ કરો",
    },
    options: {
      gender: {
        male: "માણસ",
        female: "સ્ત્રી",
        trans: "ટ્રાન્સજેન્ડર",
      },
      foods: {
        veg: "શાકાહારી",
        nonveg: "માંસાહારી",
        jain: "જૈન ભોજન",
        nosugar: "શૂગર ફ્રી",
        vegd: "વેજ (ડાયાબિટિક)",
        nonvegd: "નોન વેજ (ડાયાબિટિક)",
        snacks:"સાંજે નાસ્તો",
        tea: "ચા/કોફી",
        none: "ના.",
      },
    },
    helpers: {
      name: "નામ માન્ય ઓળખના પુરાવા અનુસાર હોવું જોઈએ.",
    },
  },
};

export default translations;
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/first */

import moment from "moment";
import React from "react";
import { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useTimer } from "react-timer-hook";
import { isProdEnv } from "../../../Helpers/getEnvironment";
import useGetReduxState from "../../../Hooks/useGetReduxState";
import Alert from "../../../UI/UIComponents/Alert";

import Loader from "./Loader";
import TimeOver from "./TimeOver";
import langText from "./translations";
import { UPIMicrophone } from "../../../Chatbot/Microphone/UPIMic";
import { updateUPIId } from "../../../Helpers/misc";
import translations from "./translations";
import { UPIPopup } from "./UPIPopup";
const disha =
  "https://cdn.jsdelivr.net/gh/corover/assets@18June/askdisha-bucket/favi.svg";

const text = {
  en: {
    header: "Back to Review Booking",
    content:
      "Doing so will cancel your current payment. Are you sure about it?",
    primaryText: "Yes",
    seconDaryText: "No",
  },
  hi: {
    header: "बुकिंग की समीक्षा पर वापस जाएं",
    content:
      "ऐसा करने से आपका वर्तमान भुगतान रद्द हो जाएगा। क्या आप इसके बारे में निश्चित हैं?",
    primaryText: "हां",
    seconDaryText: "नहीं",
  },
  gu: {
    header: "બુકીંગની સમીક્ષા પર પાછા જાઓ",

    content: "આમ કરવાથી તમારી હાલની ચુકવણી રદ થઈ જશે. શું તમને પુરી ખાતરી છે?",

    primaryText: "હા.",

    seconDaryText: "ના.",
  },
};

const audio = {
  en: "https://storage.googleapis.com/azure-blob-bucket/tts/speech_1724664675844_27c4f64a-11c6-41e7-8209-6fe19be6a1ea.mp3",
  hi: "https://storage.googleapis.com/azure-blob-bucket/tts/speech_1724664633058_d0afdd5d-e569-4678-8540-8cea5f62ebc7.mp3",
  gu: "https://storage.googleapis.com/azure-blob-bucket/tts/speech_1724664705839_b170fd5a-cedf-4e76-8a9e-1c3a5ccf8b61.mp3",
};

export const PayTm = ({
  clientTransactionId,
  txnToken,
  paymentAmount,
  handlePaymentDone,
  goBack,
}) => {
  /*** dev paytm merchantId**/
  const merchantId = "IRCTCC86226828912803";

  // const merchantId = isProdEnv
  //   ? "IRCTCC84510399265141"
  //   : "IRCTCC86226828912803";

  const lang = useGetReduxState().behaviour.lang;
  // const [showBtn, setShowBtn] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [showPaytm, setShowPaytm] = useState(false);
  const [mic, setMic] = useState(false);
  const [upiId, setUpiId] = useState("");
  const [showUPI, setShowUPI] = useState(false);
  const [hideBack, setHideBack] = useState(false);

  const time = new Date();
  time.setSeconds(time.getSeconds() + 600);
  const { seconds, minutes } = useTimer({
    expiryTimestamp: time,
  });

  useEffect(() => {
    let gID = "G-X4XL7DB3RW";
    // if (window.location.host.includes("staging")) {
    //   gID = "G-L5BLC79E9S";
    // } else if (window.location.host.includes("assistant")) {
    //   gID = "G-G6T02NG34W";
    // }
    // gID = "G-G6T02NG34W";
    // ReactGA.initialize(gID);
    // ReactGA.send({ hitType: "pageview", page: "/payment" });
    proceed();
  }, []);

  const proceed = () => {
    // ReactGA.event({
    //   category: "Payment",
    //   action: "PAYMENT",
    //   label: `Amount: INR${paymentAmount}`,
    // });
    // let txnToken = getTxnToken();
    const script = document.createElement("script");

    /*** dev paytm URL**/
    // script.src = `https://securegw-stage.paytm.in/merchantpgpui/checkoutjs/merchants/${merchantId}.js`;

    script.src = isProdEnv
      ? `https://secure.paytmpayments.com/merchantpgpui/checkoutjs/merchants/${merchantId}.js`
      : `https://securegw-stage.paytm.in/merchantpgpui/checkoutjs/merchants/${merchantId}.js`;

    script.async = true;
    script.onload = () => {
      var config = {
        root: "#PaytmDiv",

        flow: "DEFAULT",

        merchant: {
          name: "DISHA E-Ticketing",
          logo: disha,
          redirect: false,
        },
        data: {
          orderId: clientTransactionId /* update order id */,
          token: txnToken /* update token value */,
          tokenType: "TXN_TOKEN",
          amount: paymentAmount /* update amount */,
        },
        handler: {
          //@ts-ignore
          notifyMerchant: function (eventName, data) {},
          transactionStatus: (response) => {
            if (response.STATUS === "TXN_SUCCESS") {
              handlePaymentDone();
            }
          },
        },
      };
      if (window["Paytm"] && window["Paytm"].CheckoutJS) {
        window["Paytm"].CheckoutJS.onLoad(function excecuteAfterCompleteLoad() {
          // initialze configuration using init method
          window["Paytm"].CheckoutJS.init(config)
            .then(function onSuccess() {
              // setShowBtn(true);
              setShowPaytm(true);
              setShowLoader(false);

              // after successfully updating configuration, invoke JS Checkout
              window["Paytm"].CheckoutJS.invoke();
            })
            .catch(function onError(error) {
              console.log("error => ", error);
            });
        });
      } else {
        //todo
      }
    };

    document.body.appendChild(script);
  };
  const [showConfirm, setShowConfirm] = useState(false);

  return (
    <React.Fragment>
      {showLoader && <Loader />}
      <div
        style={{
          display: showPaytm && !(minutes < 1 && seconds < 1) ? "flex" : "none",
          width: "100%",
          padding: "16px",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "6px",
            background: "#f5f9fd",
            border: "2px solid #c6cdd3",
            borderRadius: "8px",
            cursor: "pointer",
          }}
          onClick={() => setMic(true)}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <img src="upi.svg" alt="" />
            <span style={{ color: "#949697", fontWeight: "bold" }}>
              Pay by voice
            </span>
          </div>
          <img
            src="forward-angle.svg"
            alt=""
            style={{ width: "16px", filter: "contrast(0.5)" }}
          />
        </div>
        <span
          style={{ fontStyle: "italic", marginTop: "10px", color: "#949697" }}
        >
          or
        </span>
      </div>
      <div
        style={{
          height: "100%",
          display:
            showPaytm && !(minutes < 1 && seconds < 1) ? "block" : "none",
          maxHeight: "calc(100% - 80px)",
        }}
        id="PaytmDiv"
      ></div>
      <div
        style={{
          display: showPaytm && !(minutes < 1 && seconds < 1) ? "flex" : "none",

          justifyContent: "space-around",
          alignItems: "center",
          zIndex: 5,
          position: "fixed",
          bottom: "16px",
          textAlign: "center",
          width: "100%",
          padding: "8px",
          background: "white",
          maxWidth: "28.125rem",
          overflow: "hidden",
        }}
      >
        <p
          style={{
            margin: 0,
            color: "#32b1fc",
            fontWeight: 500,
            cursor: "pointer",
          }}
          onClick={() => setShowConfirm(true)}
        >
          {langText[lang].back}
        </p>
        <p style={{ margin: 0, color: "#d79414", fontWeight: 500 }}>
          {langText[lang].time}:{" "}
          {moment(`${minutes}:${seconds}`, "m:s").format("mm:ss")}
        </p>
      </div>

      {showPaytm && minutes < 1 && seconds < 1 && <TimeOver />}

      {showUPI && (
        <UPIPopup
          lang={lang}
          handlePaymentDone={handlePaymentDone}
          txnToken={txnToken}
          mid={merchantId}
          orderId={clientTransactionId}
          upiId={upiId}
          onClose={() => setShowUPI(false)}
          setUpiId={setUpiId}
          setHideBack={setHideBack}
        />
      )}

      {mic && (
        <UPIMicrophone
          useEnglish={true}
          callback={(value) => {
            value && setUpiId(updateUPIId(value));
            setMic(false);
            setShowUPI(true);
          }}
          onClose={() => {
            setMic(false);
            setShowUPI(true);
          }}
          content=""
          isUPI={true}
          audio={audio[lang]}
          skipMessage={translations[lang].selectMode.skipToType}
        />
      )}

      {(mic || showUPI) && (
        <div
          style={{
            width: "100%",
            height: "calc(100% - 48px)",
            backdropFilter: "blur(10px)",
            position: "absolute",
            zIndex: "100",
            top: "0px",
          }}
        ></div>
      )}

      {showConfirm && (
        <Alert
          header={text[lang].header}
          content={text[lang].content}
          secondaryText={text[lang].seconDaryText}
          primaryText={text[lang].primaryText}
          handleSecondaryClick={() => {
            setShowConfirm(false);
          }}
          handlePrimaryClick={() => {
            setShowConfirm(false);
            if (hideBack) setShowUPI(false);
            else goBack();
          }}
        />
      )}
    </React.Fragment>
  );
};

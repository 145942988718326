import { useEffect, useState } from "react";

import useGetReduxState from "../../Hooks/useGetReduxState";
import usePersistentStorage from "../../Hooks/usePersistentStorage";
import useSendQuery from "../../Hooks/useSendQuery/useSendQuery";
import { setNextContext } from "../../Store/Dispatcher/behaviour";
import TrainsContainer from "./TrainsContainer";
import { getTrains } from "./utils";

const Trains = ({
  isDirect,
  _selectedClass,
}: {
  isDirect?: boolean;
  _selectedClass?: string;
}) => {
  const { saveJourney } = usePersistentStorage();
  const journey = useGetReduxState().journey;
  const [trains, setTrains] = useState([]);
  const [loading, setLoading] = useState(false);
  const [noTrains, setNoTrains] = useState(false);
  const [trainClass, setTrainClass] = useState(_selectedClass);
  const { sendQueryPayload, refreshPassengerFormData } = useSendQuery(() => {});
  const sendTrain = ({
    jclass,
    trainNumber,
    date,
    source,
    destination,
    quota,
    trainName,
  }) => {
    sendQueryPayload({
      data: {
        class: jclass,
        trainNumber,
        date,
        source,
        destination,
        quota,
        trainName,
      },
      query: "Train selected",
      inputType: "",
    });
  };

  const get = async ({ source, destination, date, quota }) => {
    setNoTrains(false);
    setLoading(true);
    const data = await getTrains({
      source,
      destination,
      date,
      quota,
      isDirect,
    });
    if (data && Array.isArray(data) && data.length > 0)
      setTrains(
        trainClass
          ? data.filter(
              (elem) =>
                !!elem.tickets.find((e) => e.class.toLowerCase() === trainClass)
            )
          : data
      );
    else setNoTrains(true);
    setLoading(false);
    setTrainClass(null);
  };

  useEffect(() => {
    get({
      source: journey.source,
      destination: journey.destination,
      date: journey.date,
      quota: journey.quota,
    });
    saveJourney({ source: journey.source, destination: journey.destination });
    setNextContext("ade4a7db-d819-417d-832a-259307fd94c7,8");
    refreshPassengerFormData();
  }, []);

  const updateCache = ({
    selectedQuota,
    selectedClass,
    train,
    selectedDate,
    fare,
    status,
  }) => {
    if (selectedQuota === journey.quota) {
      let t = [...trains];
      let targetTrain = t.filter(
        (trainM) => trainM.trainNumber === train.trainNumber
      )[0];
      if (targetTrain) {
        let tickets = targetTrain.tickets;
        let targetTicket = tickets.filter(
          (ticket) => ticket.class === selectedClass
        )[0];

        if (targetTicket && train.departureDate === selectedDate) {
          targetTicket.fare = fare;
          targetTicket.cached = true;

          targetTicket.availablityStatus = status;
          targetTicket.time = "few seconds ago";
          let ind1 = tickets.findIndex(
            (ticket) => ticket.class === selectedClass
          );
          tickets[ind1] = targetTicket;
          targetTrain.tickets = tickets;
          let ind2 = t.findIndex(
            (trainM) => trainM.trainNumber === train.trainNumber
          );

          t[ind2] = targetTrain;
          setTrains(t);
        }
      }
    }
  };

  return (
    <TrainsContainer
      trains={trains}
      getTrains={get}
      loading={loading}
      noTrains={noTrains}
      updateCache={updateCache}
      sendTrain={sendTrain}
    />
  );
};

export default Trains;

import moment from "moment";
import { useState } from "react";

import Dislike from "../../../../../../../Templates/Dislike";
import Like from "../../../../../../../Templates/Like";
import Speaker from "./Speaker";
import Container from "./styles";
const Bharat = require("../../../../../../../Assets/Local/Bharat.png");
const avatar = require("../../../../../../../Assets/Local/disha-message-avatar.ico");
const Message = ({ id, text, audioUrl, sender, render, intent }) => {
  const [openDrawer, setOpenDrawer] = useState("");
  const [showFeedBack, setShowFeedback] = useState(true);
  return (
    <Container sender={sender}>
      {sender === "BOT" && <img src={avatar} alt="" />}
      <div className="message-container">
        <div className="message-text">
          {sender === "BOT" ? (
            <p dangerouslySetInnerHTML={{ __html: String(text) }}></p>
          ) : (
            <p>{text}</p>
          )}

          <p></p>
          {sender === "BOT" && audioUrl && (
            <Speaker audioUrl={audioUrl} id={id} render={render} />
          )}
          {/* {sender === "BOT" && text?.includes("?") && (
            <img
              className="question-mark"
              src="https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/question.svg"
              alt=""
              width="32px"
            />
          )} */}
        </div>

        <div className="message-toolbar">
          <span 
          style={{
            whiteSpace: 'nowrap',
          }}
          className="timestamp">{moment().format("hh:mm a").toUpperCase()}</span>
          {showFeedBack && sender === "BOT" && (
            <div
            style={{
              whiteSpace:'nowrap',
            }}>
              {intent === "FAQ" &&
              <img
                className="Bharat"
                src={Bharat}
                id="Bharat"
                alt=""
                style={{
                  width: '25%',
                  margin: '0% 3% 0% 58%',
                  cursor: 'default',
                }}
              />}
              <img
                className="feedback-btn"
                src="https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/dislike.svg"
                id="dislike"
                alt=""
                onClick={() => {
                  setOpenDrawer("DISLIKE");
                }}
                style={{
                  marginRight:'5px'
                }}
              />
              <img
                className="feedback-btn"
                src="https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/like.svg"
                id="like"
                alt=""
                onClick={() => {
                  setOpenDrawer("LIKE");
                  setTimeout(() => {
                    setOpenDrawer("");
                    setShowFeedback(false);
                  }, 3000);
                }}
              />
            </div>
          )}
        </div>
      </div>
      {openDrawer === "LIKE" && <Like answerId={id} />}
      {openDrawer === "DISLIKE" && (
        <Dislike
          answerId={id}
          handleClose={() => {
            setOpenDrawer("");
          }}
          handleSent={() => {
            setOpenDrawer("");
            setShowFeedback(false);
          }}
        />
      )}
    </Container>
  );
};

export default Message;

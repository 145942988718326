import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import useGetReduxState from "../../../../../Hooks/useGetReduxState";
import { setFaqsForLang } from "../../../../../Store/Actions/faqs";
import {
  setAppLang,
  setQueryLang,
} from "../../../../../Store/Dispatcher/behaviour";
import Drawer from "../../../../../UI/UIComponents/Drawer";
import Container from "./styles";
const img = require("../../../../../Assets/Local/bhashini.webp");
const uk =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/flag-uk-refactored.png";
const us =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/flag-us-refactored.png";
const ind =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/flag-india-refactored.png";

const Header = () => {
  const lang = useGetReduxState().behaviour.lang;

  if (lang === "en") return <div>Select Language</div>;

  if (lang === "hi") return <div>भाषा का चयन करें</div>;

  if (lang === "gu") return <div>ભાષા પસંદ કરો</div>;
};

const Content = ({ handleClose }) => {
  const [checkHeightResult, setCheckHeightResult] = useState(false);
  const queryLang = useGetReduxState().behaviour.queryLang;
  const dispatch = useDispatch();

  const handleClick = (lang) => {
    setQueryLang(lang);

    if (lang === "hi") {
      setAppLang("hi");
    } else if (lang === "gu") {
      setAppLang("gu");
    } else {
      setAppLang("en");
    }

    dispatch(setFaqsForLang(lang));
    handleClose();
  };

  useEffect(() => {
    const result = checkHeight();
    setCheckHeightResult(result);
  }, []);

  // Your checkHeight function
  const checkHeight = () => {
    const bodyElement = document.getElementById('corover-body');
    return bodyElement ? bodyElement.offsetHeight > 310 : false;
  };

  return (
    <Container isCheckHeight={checkHeightResult}>
      <div className="row">
        <div
          className={`${queryLang === "en" ? "tab-active" : "tab"}`}
          onClick={() => handleClick("en")}
          tabIndex={1}
          aria-label="English - India"
          onKeyDown={(e) => {
            if (e.key === "Enter") handleClick("en");
          }}
        >
          <div className="head" style={{}}>
            <h2>Aa</h2>
            <p>English</p>
          </div>
          <div className="flag">
            <p>India</p>
            <img src={ind} alt="" />
          </div>
        </div>
        <div
          className={`${queryLang === "hi" ? "tab-active" : "tab"}`}
          onClick={() => handleClick("hi")}
          tabIndex={1}
          aria-label="Hindi"
          onKeyDown={(e) => {
            if (e.key === "Enter") handleClick("hi");
          }}
        >
          <div className="head" style={{}}>
            <h2>आ</h2>
            <p>हिन्दी</p>
          </div>

          <div className="flag">
            <p>India</p>
            <img src={ind} alt="" />
          </div>
        </div>
      </div>
      <div className="row">
        <div
          className={`${queryLang === "gu" ? "tab-active" : "tab"}`}
          onClick={() => handleClick("gu")}
          tabIndex={1}
          aria-label="Gujarati"
          onKeyDown={(e) => {
            if (e.key === "Enter") handleClick("gu");
          }}
          style={{ position: "relative", width: "93%" }}
        >
          <div className="head" style={{}}>
            <h2>એ</h2>
            <p>Gujarati (ગુજરાતી)</p>
          </div>

          <div className="flag">
            <p>India</p>
            <img src={ind} alt="" />
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              columnGap: "0px",
              position: "absolute",
              bottom: "3px",
              right: "20px",
            }}
          >
            <span style={{ fontSize: "12px", color: "#3a3a3a" }}>
              Powered by
            </span>
            <img style={{ width: "50px" }} src={img} alt="" />
          </div>
        </div>
      </div>

      <div className="row" style={{ marginTop: checkHeightResult ? "28px" : "10px" }}>
      <div
        className={`${queryLang === "us" ? "tab-active" : "tab"}`}
        onClick={() => handleClick("us")}
        tabIndex={1}
        aria-label="English - US"
        onKeyDown={(e) => {
          if (e.key === "Enter") handleClick("us");
        }}
      >
        <div className="head">
          <h2>Aa</h2>
          <p>English</p>
        </div>
        <div className="flag">
          <p>USA</p>
          <img src={us} alt="USA Flag" />
        </div>
      </div>
      <div
        className={`${queryLang === "gb" ? "tab-active" : "tab"}`}
        onClick={() => handleClick("gb")}
        tabIndex={1}
        aria-label="English - UK"
        onKeyDown={(e) => {
          if (e.key === "Enter") handleClick("gb");
        }}
      >
        <div className="head">
          <h2>Aa</h2>
          <p>English</p>
        </div>
        <div className="flag">
          <p>UK</p>
          <img src={uk} alt="UK Flag" />
        </div>
      </div>
    </div>
    </Container>
  );
};

const LanguageMenu = ({ handleClose }) => {
  return (
    <Drawer
      content={<Content handleClose={handleClose} />}
      header={<Header />}
      footer={<React.Fragment></React.Fragment>}
      showCross={true}
      showHeaderSeperator={true}
      tatkal={false}
      fullHeight={false}
      handleCloseDrawer={handleClose}
    />
  );
};

export default LanguageMenu;

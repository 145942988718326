import { useState } from "react";
import { beta, disha, menu, reset } from "../../Assets/Remote/images";
import useGetReduxState from "../../Hooks/useGetReduxState";
import useSendQuery from "../../Hooks/useSendQuery/useSendQuery";
import {
  setFullScreen,
  setUpcomingBookings,
} from "../../Store/Dispatcher/behaviour";
import { setJourney } from "../../Store/Dispatcher/journey";
import { deleteLastNmessages } from "../../Store/Dispatcher/messages";
import TrainDetails from "../../Templates/TrainDetails";
import Trains from "../../Templates/Trains";
import Alert from "../../UI/UIComponents/Alert";
import IrctcLogo from "../../UI/UIComponents/IrctcLogo/IrctcLogo";
import Menu from "../Menu";
import HeaderContainer from "./Header.styles";
const text = {
  en: {
    ask: "Ask",
    disha: "DISHA",
    header: "Are you sure?",
    content: "Your current conversation will be terminated.",
    primaryText: "Confirm",
    seconDaryText: "Cancel",
  },
  hi: {
    ask: "आस्क",
    disha: "दिशा",
    header: "क्या आप इसके बारे में निश्चित हैं?",
    content: "आपकी वर्तमान बातचीत समाप्त कर दी जाएगी।",
    primaryText: "जारी रखें",
    seconDaryText: "रद्द करें",
  },
  gu: {
    ask: "એક",
    disha: "દિશા",
    header: "શું તમને ખાતરી છે?",

    content: "તમારી હાલની વાતચીત સમાપ્ત થઈ જશે.",

    primaryText: "પુષ્ટિ કરો",

    seconDaryText: "રદ કરો",
  },
};

const Header = () => {
  const fullScreen = useGetReduxState().behaviour.fullScreenComponent;
  const bookings = useGetReduxState().behaviour.bookings;
  const { goToHome } = useSendQuery(() => {});
  const lang: "hi" | "en" | "gu" = useGetReduxState().behaviour.lang;
  const [open, setOpen] = useState(false);
  const messages = useGetReduxState().messages;
  const handleOpenMenu = () => setOpen(true);
  const { nextContext } = useGetReduxState().app;

  
  const findTrains = ({ source, destination, date, quota }) => {
    setJourney({ source, destination, quota, date, boardingStation: "" });
    setFullScreen({
      type: "TRAINS",
      component: <Trains />,
    });
    deleteLastNmessages(3);
  };
  const [showConfirm, setShowConfirm] = useState(false);

  return (
    <HeaderContainer>
      <div className="bg">
        <div className="first-column">
          <div className="buttons">
            <img
              src={menu}
              alt="Open Menu Button"
              className="btn-menu"
              onClick={handleOpenMenu}
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === "Enter") handleOpenMenu();
              }}
            />
            <img
              src={reset}
              alt="Go To Home Button"
              className="btn-reset"
              onClick={() => {
                if (messages.length > 0 || fullScreen.type || bookings)
                  setShowConfirm(true);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  if (messages.length > 0 || fullScreen.type)
                    setShowConfirm(true);
                }
              }}
              tabIndex={0}
            />
          </div>
          <img
            tabIndex={0}
            className="disha-logo"
            src={disha}
            alt="Ask Disha Avatar"
          />
          <div
            tabIndex={0}
            aria-label="Ask Disha 2.0 Heading Title"
            className="header-text"
          >
            <p>{text[lang].ask}</p>
            <h3>{text[lang].disha}</h3>
            <span>2.0</span>
          </div>
        </div>

        <div tabIndex={0} title="IRCTC Logo" className="second-column">
          <img className="beta" src={beta} alt="" />
          <IrctcLogo />
        </div>
      </div>

      {open && <Menu handleClose={() => setOpen(false)} />}
      {nextContext &&
        parseInt(nextContext.split(",")[1]) > 8 &&
        parseInt(nextContext.split(",")[1]) < 12 && (
          <div
            style={{
              position: "absolute",
              zIndex: "2",
              top: "100%",
              width: "100%",
              background: "white",
              left: "0px",
            }}
          >
            <TrainDetails findTrains={findTrains} />
          </div>
        )}
      {showConfirm && (
        <Alert
          header={text[lang].header}
          content={text[lang].content}
          secondaryText={text[lang].seconDaryText}
          primaryText={text[lang].primaryText}
          handleSecondaryClick={() => {
            setShowConfirm(false);
          }}
          handlePrimaryClick={() => {
            setShowConfirm(false);
            goToHome();
            setUpcomingBookings(false);
          }}
        />
      )}
    </HeaderContainer>
  );
};

export default Header;

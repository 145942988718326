import moment from "moment";
import { useEffect } from "react";

import useFirstLoadSetup from "../Hooks/useFirstLoadSetup";
import useGetReduxState from "../Hooks/useGetReduxState";
// import useGetReduxState from "../Hooks/useGetReduxState";

import { setFullScreen } from "../Store/Dispatcher/behaviour";
import {
  setJourneyDate,
  setJourneyDestination,
  setJourneyQuota,
  setJourneySource,
} from "../Store/Dispatcher/journey";
import { addResponseMessage } from "../Store/Dispatcher/messages";
import{ deleteAllMessages } from "../Store/Dispatcher/messages";

import PendingBooking from "../Templates/PendingBooking";
import Trains from "../Templates/Trains";
import Alert from "../UI/UIComponents/ErrorAlert/Alert";

import Body from "./Body";
import Footer from "./Footer";
import Header from "./Header";
import { sendClick } from "../Api/sendClick";
let shouldRenderHeader;

const getQuota = (quota) => {
  switch (quota) {
    case "GENERAL":
      return "GN";
    case "LADIES":
      return "LD";
    case "TATKAL":
      return "TQ";
    case "PREMIUM TATKAL":
      return "TQ";
    case "LOWER BERTH/SR.CITIZEN":
      return "SS";
    case "GN":
      return "GN";
    case "LD":
      return "LD";
    case "TQ":
      return "TQ";
    case "TQ":
      return "TQ";
    case "SS":
      return "SS";
    default:
      return "GN";
  }
};
// http://localhost:3000/eticket?FROM=NDLS&TO=MMCT&DATE=20221217&QUOTA=GN



const lan = {
  en: { greeting: "Hey! I'm Disha. How can I help you today?" },
  hi: { greeting: "नमस्ते! मैं दिशा हूँ। मैं आज आपकी किस प्रकार मदद कर सकती हूँ?" },
  gu: { greeting: "હાય! હું દિશા છું. હું આજે તમારી કેવી રીતે મદદ કરી શકું છું?" },
};

const Chatbot = () => {
  const { showPending, setShowPending } = useFirstLoadSetup();
  const isUnAuth = useGetReduxState().app[401];
  const lang = useGetReduxState().behaviour.lang; // Get lang from Redux


  useEffect(() => {
    // Existing functionality that runs once on component mount
    window.addEventListener("message", (e) => {
      const data = e.data;
      if (data.source && data.destination && data.quota && data.date) {
        setFullScreen({ type: null, component: null });
        const source = data.source.split("(")[0].trim();
        setJourneySource(source);
        const destination = data.destination.split("(")[0].trim();
        setJourneyDestination(destination);
        const date = data.date;
        setJourneyDate(date);
        const quota = getQuota(data.quota);
        setJourneyQuota(quota);
        setTimeout(() => {
          setFullScreen({
            type: "TRAINS",
            component: <Trains isDirect={true} />,
          });
        }, 500);
      }
    });

    if (window.location.search) {
      try {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString.toLowerCase());
        const source = urlParams.get("from");
        const destination = urlParams.get("to");
        const date = urlParams.get("date");
        const quota = getQuota((urlParams.get("quota") ?? "GN").toUpperCase());
        const support = urlParams.get("support");

        if (source && destination && date && quota) {
          const selectedClass = urlParams.get("class");
          setJourneySource(source?.split("(")[0].trim().toUpperCase() || "");
          setJourneyDestination(destination?.split("(")[0].trim().toUpperCase() || "");
          setJourneyDate(date);
          setJourneyQuota(quota.toUpperCase());
          setFullScreen({
            type: "TRAINS",
            component: <Trains _selectedClass={selectedClass} />,
          });
          sendClick("TRAINS");
        } else if (!support) {
          sendClick("HOME");
        }

        shouldRenderHeader = support && JSON.parse(support);
        // npm 
      } catch (err) {}
    }
  }, []);  // Existing useEffect logic (unchanged)

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        background: "white",
        maxWidth: "28.125rem",
        margin: "0 auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "stretch",
        flexGrow: 0,
      }}
    >
      {!shouldRenderHeader && <Header />}
      <Body support={shouldRenderHeader} />
      <Footer />
      {showPending && (
        <PendingBooking
          data={showPending}
          hide={() => setShowPending(false)}
        />
      )}
      {isUnAuth && (
        <Alert
          error={"Session Time Out!"}
          suberror={"It looks like your session has been timed out. Click OK to start a new session."}
          handleClose={() => window.location.reload()}
        />
      )}
    </div>
  );
};

export default Chatbot;

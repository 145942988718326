const translations = {
  en: {
    listening: "Listening...",
    try: "Try Saying",
    suggestions: {
      book: "I want to book a Ticket",
      upi: "Say UPI, Card, Netbanking, etc.",
      upiId: "Eg. 9876543210@bank",
    },
    likeToPay: "How would you like to pay?",
    payingUPI: "- Pay via UPI -",
    provideId: "Please provide your UPI ID or Mobile Number",
  },
  hi: {
    listening: "आपको सुन रही हूँ...",
    try: "यह कहने का प्रयास करें",
    suggestions: {
      book: "मुझे टिकट बुक करनी है",
      upi: "यूपीआई, कार्ड, नेटबैंकिंग आदि कहें।",
      upiId: "जैसे 9876543210@bank",
    },
    likeToPay: "आप किस तरह से भुगतान करना चाहेंगे?",
    payingUPI: "- यूपीआई द्वारा भुगतान करें -",
    provideId: "कृपया अपनी यूपीआई आईडी या मोबाइल नंबर प्रदान करें",
  },
  gu: {
    listening: "હું તમને સાંભળું છું...",
    try: "તે કહેવાનો પ્રયાસ કરો",
    suggestions: {
      book: "મારે ટિકિટ બુક કરાવવાની છે",
      upi: "UPI, કાર્ડ, નેટબેંકિંગ વગેરે કહો.",
      upiId: "જેમ 9876543210@bank",
    },
    likeToPay: "તમે કેવી રીતે ચૂકવણી કરવા માંગો છો?",
    payingUPI: "- યુપીઆઈનો દ્વારા ચૂકવણી કરો -",
    provideId: "કૃપા કરીને તમારું યુપીઆઈનો આઈડી અથવા મોબાઇલ નંબર આપો",
  },
};

export default translations;

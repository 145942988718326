import useGetReduxState from "../../../../../../Hooks/useGetReduxState";
import { useEffect, useState } from "react";
import Message from "./Message";
import Container from "./styles";
import { Message as MessageBody } from "../../../../../../Store/Reducers/types";
import Loader from "./Loader";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../Store/Reducers";
import AlsoTry from "../../../../../../AlsoTry";
import { useElementSize } from "@mantine/hooks";

interface MessagesProps {
  messages: MessageBody[];
}

const Messages: React.FC<MessagesProps> = ({ messages }) => {
  const { ref } = useElementSize();
  const [showAlsoTry, setShowAlsoTry] = useState(false);

  const { showMessageLoader } = useSelector((state: RootState) => state.behaviour);
  const alsoTryQuestions = useGetReduxState().behaviour.alsoTry;

  useEffect(() => {
    const checkHeight = () => {
      const bodyElement = document.getElementById("corover-body");
      if (bodyElement) {
        setShowAlsoTry(bodyElement.offsetHeight > 290);
      }
    };

    checkHeight(); // Check height on component mount
    window.addEventListener("resize", checkHeight); // Check height on window resize

    return () => {
      window.removeEventListener("resize", checkHeight); // Cleanup listener
    };
  }, [messages]); // Rerun when messages change

  useEffect(() => {
    // Scroll to the latest message container when messages change
    const scrollToLatestMessage = () => {
      const messageContainers = document.getElementsByClassName("message-container");
      if (messageContainers.length > 0) {
        const lastMessageContainer = messageContainers[messageContainers.length - 1];
        lastMessageContainer.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    };
    
    scrollToLatestMessage();
  }, [messages]); // Trigger scroll on messages change

  return (
    <Container ref={ref} id="corover-messages-box" tabIndex={0}>
      <div id="corover-body">
        {messages.map((message) => (
          <div key={message.id} className="message-container" style={{ width: "100%", marginTop: "15px" }}>
            {message.type === "TEXT" ? (
              <Message
                id={message.id}
                text={message.text}
                audioUrl={message.audioUrl}
                sender={message.sender}
                render={message.render || ""}
                intent={message.intent || ""}
              />
            ) : (
              message.component
            )}
          </div>
        ))}
      </div>

      {showMessageLoader && <Loader />}
      {!showMessageLoader && showAlsoTry && alsoTryQuestions.length > 0 && (
        <AlsoTry questions={alsoTryQuestions} />
      )}
    </Container>
  );
};

export default Messages;

import moment from "moment";
import { useEffect } from "react";

import Container from "./styles";
import { parseData } from "./utils";
import langText from "./translations";
import useGetReduxState from "../../Hooks/useGetReduxState";
import useSendQuery from "../../Hooks/useSendQuery/useSendQuery";

const icon =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/cancle.svg" as string;
const CancelTicket = ({ data }) => {
  const lang: "en" | "gu" | "hi" = useGetReduxState().behaviour.lang;
  const { refresh } = useSendQuery(() => {});
  const {
    pnr,
    cancellationId,
    date,
    paidAmount,
    deductedAmount,
    refundAmount,
    passengers,
    message,
  } = parseData(data);

  useEffect(() => {
    refresh();
  }, []);

  return (
    <Container>
      <div className="header">
        <img src={icon} alt="" />
        <p>{langText[lang].header}</p>
      </div>
      <div className="first-details">
        <div>
          <p>{langText[lang].pnr}:</p>
          <p>{langText[lang].cancelId}:</p>
          <p>{langText[lang].date}:</p>
          <p>{langText[lang].time}:</p>
        </div>
        <div>
          <p>{pnr}</p>
          <p>{cancellationId}</p>
          <p>{moment(date).format("DD MMMM, YYYY")}</p>
          <p>{moment(date).format("hh:mm a")}</p>
        </div>
      </div>
      <div className="seperator"></div>
      <div className="money">
        <p>
          {langText[lang].paidAmount}: <span> ₹{paidAmount}</span>
        </p>
        <p>
          {langText[lang].deductedAmount}: <span> ₹{deductedAmount}</span>
        </p>
        <div className="hr"></div>
        <h2>
          {langText[lang].refundAmount}: <span> ₹{refundAmount}</span>
        </h2>
      </div>
      <div className="seperator"></div>
      {passengers.length > 0 ? (
        <div className="passengers">
          <h4>
            {langText[lang].passengers} ({passengers.length})
          </h4>
          {passengers.map((passenger, index) => (
            <p>
              {index + 1} {passenger}
            </p>
          ))}
        </div>
      ) : (
        <p style={{ fontSize: "14px" }}>{message}</p>
      )}
    </Container>
  );
};

export default CancelTicket;

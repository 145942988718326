import styled from "styled-components";



const AdUnit = styled.div`
  display: block !important;
  background-image: url(https://cdn.jsdelivr.net/gh/corover/assets@a1/askdisha-bucket/300_250.png);
  background-origin: content-box;
  background-repeat: no-repeat;
  background-size: contain !important;
  background-position: center;
  min-width: 300px;
  min-height: 250px;
`;
export  default AdUnit;

import { useEffect } from "react";
import { useSelector } from "react-redux";
import { stopAudio } from "../../../../Store/Dispatcher/behaviour";
import { RootState } from "../../../../Store/Reducers";
import HomeScreen from "./Components/Home";
import AD from "./Components/Home/AD/index";
import Messages from "./Components/Messages";
import Container from "./styles";
import QuickButtons from "./Components/Messages/QuickButtons";

const Content = () => {
  const messages = useSelector((state: RootState) => state.messages);
  const checkHeight = () => {
    const bodyElement = document.getElementById("corover-body");
    return bodyElement ? bodyElement.offsetHeight > 310 : false;
  };

  useEffect(() => {
    const bodyElement = document.getElementById("corover-body");
    let audioPlayer: any = document.getElementById(
      "myaudio",
    ) as HTMLAudioElement;
    audioPlayer.addEventListener(
      "ended",
      () => {
        stopAudio();
      },
      false,
    );
  }, []);
  return (
    <Container>
      {messages.length > 0 ? (
        <>
          {" "}
          <Messages messages={messages} />{" "}
        </>
      ) : (
        <>
        {!checkHeight() && <AD />}
         {checkHeight() && <HomeScreen />}
         </>
      )}
    </Container>
  );
};

export default Content;

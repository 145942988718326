const translations = {
  en: {
    headers: {
      "1": {
        head: "Enter IRCTC User Id",
        text: "Verify your IRCTC User ID",
      },
      "2": {
        head: "Passenger Details",
        text: "Please select passengers for the journey",
      },
      "3": {
        head: "Choose Preferences",
        text: "Please choose preferences for the journey",
      },
      step: "Step",
    },
    utils: {
      years: "years",
      male: "Male",
      female: "Female",
      transgender: "Transgender",
      adult: "Adult",
      child: "Child",
      infant: "Infant",
      food: {
        full: {
          veg: "Vegetarian",
          vegd: "Vegetarian (Diabetic)",
          nonvegd: "Non-Vegetarian (Diabetic)",
          jain: "Jain meal",
          nonveg: "Non-Vegetarian",
          snacks: "Evening-Snacks",
          tea: "Tea/Coffee",
          none: "No Food Choice",
        },
        short: {
          veg: "Veg",
          nonveg: "Non-Veg",
          vegd: "Veg (Diabetic)",
          nonvegd: "Non-Veg (Diabetic)",
          jain: "Jain meal",
          snacks: "Snacks",
          tea: "Tea/Coffee",
          none: "None",
        },
      },
      span: {
        adult: "12 years and above",
        child: "5 to 11 years",
        infant: "0 to 4 years",
      },
    },
  },
  hi: {
    headers: {
      "1": {
        head: "IRCTC User Id दर्ज करें",
        text: "अपने IRCTC User Id की पुष्टि करें",
      },
      "2": {
        head: "यात्री विवरण",
        text: "कृपया यात्रा के लिए यात्रियों का चयन करें",
      },
      "3": {
        head: "प्राथमिकताएं का चयन",
        text: "कृपया यात्रा के लिए प्राथमिकताएं चुनें",
      },
      step: "चरण",
    },
    utils: {
      years: "वर्ष",
      male: "पुरुष",
      female: "महिला",
      transgender: "ट्रांसजेंडर",
      adult: "बालिग",
      child: "बालक",
      infant: "शिशु",
      food: {
        full: {
          veg: "शाकाहारी",
          nonveg: "मांसाहारी",
          vegd: "शाकाहारी (डायबिटिक)",
          nonvegd: "मांसाहारी (डायबिटिक)",
          jain: "जैन भोजन",
          snacks: "शाम का नाश्ता",
          tea: "चाय/कॉफी",
          none: "नहीं",
        },
        short: {
          veg: "शाका",
          nonveg: "मांसा",
          vegd: "वेज (डायबिटिक)",
          nonvegd: "नॉन वेज (डायबिटिक)",
          jain: "जैन भोजन",
          snacks: "नाश्ता",
          tea: "चाय/कॉफी",
          none: "नहीं",
        },
      },
      span: {
        adult: "11 वर्ष से अधिक",
        child: "5 से 11 वर्ष",
        infant: "0 से 4 वर्ष",
      },
    },
  },
  gu: {
    headers: {
      "1": {
        head: "આઈ. આર. સી. ટી. સી. વપરાશકર્તા ID દાખલ કરો",
        text: "તમારા આઈ. આર. સી. ટી. સી. વપરાશકર્તા ઓળખની ચકાસણી કરો",
      },
      "2": {
        head: "મુસાફરોની વિગતો",
        text: "કૃપા કરીને મુસાફરી માટે મુસાફરોને પસંદ કરો",
      },
      "3": {
        head: "પ્રાથમિકતાઓની પસંદગી",
        text: "મહેરબાની કરીને મુસાફરી માટેની પસંદગીઓ પસંદ કરો.",
      },
      step: "તબક્કો",
    },
    utils: {
      years: "વર્ષ",
      male: "માણસ",
      female: "સ્ત્રી",
      transgender: "ટ્રાન્સજેન્ડર",
      adult: "બાલિગ",
      child: "બાળક",
      infant: "બાળક",
      food: {
        full: {
          veg: "શાકાહારી",
          nonveg: "માંસાહારી",
          vegd: "શાકાહારી (ડાયાબિટિક)",
          nonvegd: "માંસાહારી (ડાયાબિટિક)",
          jain: "જૈન ભોજન",
          snacks: "સાંજે નાસ્તો",
          tea: "ચા/કોફી",
          none: "ના.",
        },
        short: {
          veg: "શક",
          nonveg: "માંસ",
          vegd: "વેજ (ડાયાબિટિક)",
          nonvegd: "નોન વેજ (ડાયાબિટિક)",
          jain: "જૈન ભોજન",
          snacks: "નાસ્તો",
          tea: "ચા/કોફી",
          none: "ના.",
        },
      },
      span: {
        adult: "11 વર્ષથી વધુ",
        child: "5 થી 11 વર્ષ",
        infant: "0 થી 4 વર્ષ",
      },
    },
  },
};

export default translations;
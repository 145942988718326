import axios from "axios";
// import DbStorage from "../../services/dbService";
let cancelToken;
export const getNearbyStations = async ({ latitude, longitude }) => {
  try {
    const response = await axios.get(
      `/dishaAPI/bot/stationsByLocation/${latitude}/${longitude}`,
    );

    return { data: response.data, error: false };
  } catch (e) {
    return { data: [], error: true };
  }
};

export const searchStation = async (key) => {
  if (cancelToken) {
    cancelToken.cancel("Operation cancelled due to new request");
  }
  cancelToken = axios.CancelToken.source();
  try {
    const response = await axios.get(`/dishaAPI/bot/searchStation/${key}`, {
      cancelToken: cancelToken.token,
    });
    return response.data;
  } catch (e) {
    return -1;
  }
};

export const getStationsfromCodes = async (codes) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "app-id": "29fd4f94-f793-4227-9588-056b5ffb1318",
      "auth-Key": "2b5fb5d4-0753-4302-b661-f8580e9effb0",
    },
  };

  let payload = {
    codes: codes,
  };
  try {
    const response = await axios.post(
      `/dishaAPI/bot/codeToStation`,
      payload,
      config,
    );

    return response.data;
  } catch (e) {
    return -1;
  }
};

export const getAllStations = async () => {
  try {
    const response = await axios.get(
      "https://cdn.jsdelivr.net/gh/corover/assets@Stations/askdisha-bucket/stations.json",
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );

    return response.data;
  } catch {
    return [];
  }
};

export const getPopularStations = async () => {
  try {
    const response = await axios.get(
      "https://cdn.jsdelivr.net/gh/corover/assets@t52/askdisha-bucket/popular.json",
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );

    return response.data;
  } catch {
    return [];
  }
};
